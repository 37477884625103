import React from "react"

export default () => (
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.7001 8.65833C8.2251 8.65833 7.8501 9.07499 7.8501 9.58333C7.8501 10.0917 8.23343 10.5083 8.7001 10.5083C9.1751 10.5083 9.5501 10.0917 9.5501 9.58333C9.55843 9.07499 9.1751 8.65833 8.7001 8.65833ZM11.7418 8.65833C11.2668 8.65833 10.8918 9.07499 10.8918 9.58333C10.8918 10.0917 11.2751 10.5083 11.7418 10.5083C12.2168 10.5083 12.5918 10.0917 12.5918 9.58333C12.5918 9.07499 12.2168 8.65833 11.7418 8.65833Z"
      fill="white"
    />
    <path
      d="M15.7915 1.66666H4.62484C3.68317 1.66666 2.9165 2.43332 2.9165 3.38332V14.65C2.9165 15.6 3.68317 16.3667 4.62484 16.3667H14.0748L13.6332 14.825L14.6998 15.8167L15.7082 16.75L17.4998 18.3333V3.38332C17.4998 2.43332 16.7332 1.66666 15.7915 1.66666ZM12.5748 12.55C12.5748 12.55 12.2748 12.1917 12.0248 11.875C13.1165 11.5667 13.5332 10.8833 13.5332 10.8833C13.1915 11.1083 12.8665 11.2667 12.5748 11.375C12.1582 11.55 11.7582 11.6667 11.3665 11.7333C10.5665 11.8833 9.83317 11.8417 9.20817 11.725C8.73317 11.6333 8.32484 11.5 7.98317 11.3667C7.7915 11.2917 7.58317 11.2 7.37484 11.0833C7.34984 11.0667 7.32484 11.0583 7.29984 11.0417C7.28317 11.0333 7.27484 11.025 7.2665 11.0167C7.1165 10.9333 7.03317 10.875 7.03317 10.875C7.03317 10.875 7.43317 11.5417 8.4915 11.8583C8.2415 12.175 7.93317 12.55 7.93317 12.55C6.0915 12.4917 5.3915 11.2833 5.3915 11.2833C5.3915 8.59999 6.5915 6.42499 6.5915 6.42499C7.7915 5.52499 8.93317 5.54999 8.93317 5.54999L9.0165 5.64999C7.5165 6.08332 6.82484 6.74166 6.82484 6.74166C6.82484 6.74166 7.00817 6.64166 7.3165 6.49999C8.20817 6.10832 8.9165 5.99999 9.20817 5.97499C9.25817 5.96666 9.29984 5.95832 9.34984 5.95832C9.85817 5.89166 10.4332 5.87499 11.0332 5.94166C11.8248 6.03332 12.6748 6.26666 13.5415 6.74166C13.5415 6.74166 12.8832 6.11666 11.4665 5.68332L11.5832 5.54999C11.5832 5.54999 12.7248 5.52499 13.9248 6.42499C13.9248 6.42499 15.1248 8.59999 15.1248 11.2833C15.1248 11.2833 14.4165 12.4917 12.5748 12.55Z"
      fill="white"
    />
  </svg>
)
